import { Box, Divider, Grid, Typography,Button } from "@mui/material";
import * as React from "react";
import { IMAGES } from "../../assets/images";
import { useNavigate,useParams } from "react-router-dom";

import * as components from "../../components";
import style from "../compoundComponents.module.scss";
import { dashboardConst, errorConst } from "../../utils/text-constants";

/**
 * reference page:
 * view guide (path: /admin/view-guide) or
 * view explorer page (path: /admin/explorer-details)
 */

const DetailsViewPageComponent = (props: any): JSX.Element => {
  const {
    pageState,
    setPageState,
    viewData,
    button1,
    button2,
    tableColumns = [],
    showViews,
    handleButtonClick,
  } = props;
  const { userId } = useParams<{ userId: string }>();

  const navigate = useNavigate();
  let guideStatus:any = localStorage.getItem("guidestatus");

  return (
    <>
     {viewData && guideStatus&&guideStatus==='PendingApproval'&& <Grid container spacing={2} mb={2}>
            <Grid item xs={6}>
              <Button
                sx={[buttonStyle, {
                  color: "white",
                  backgroundColor: "#FE6331",
                  "&:hover": {
                    borderColor: "#FE6331",
                    backgroundColor: "rgba(254, 99, 49, 0.8)"
                  },
                }]}
                variant="outlined"
                onClick={() => handleButtonClick("Approve")} 
              >
                Guide Approval
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={[buttonStyle]}
                variant="outlined"
                onClick={() => handleButtonClick("Reject")}
              >
                Guide Reject
              </Button>
            </Grid>
          </Grid>}
      {!viewData?.isLoading && viewData?.data && (
        <Grid container spacing={3} p={2.5} pl={3}>
          <Grid
            item
            sm={12}
            md={12}
            lg={12}
            display="flex"
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems="center"
          >
            <components.RoundImageComponent
              src={viewData?.profileImage || IMAGES.emptyProfileImg}
            />
            <Typography className={style.name}>{viewData?.name}</Typography>
          </Grid>
          <Grid item sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          {Array.isArray(viewData?.data) &&
            viewData.data.map((eachData: any, index: number) => {
              if (eachData.displayType === "taggings") {
                return (
                  <Grid item sm={12} lg={12} md={12} key={index}>
                    <components.SimpleLableDataComponent label={eachData.label} />
                    <components.SimpleTagList tagsList={eachData.data} />
                  </Grid>
                );
              }
              return (
                <Grid item sm={12} lg={6} md={6} key={index}>
                  <components.SimpleLableDataComponent
                    label={eachData.label}
                    value={eachData.data}
                  />
                </Grid>
              );
            })}
          <Grid item sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
          <Grid item sm={12} lg={12} md={12}>
            {showViews?.button1 && (
              <components.LoadingButtonComponent
                buttonClassName={style.editButton}
                buttonLabel={button1.label || "Edit"}
                labelImg={button1?.image || IMAGES.editPencilIcon}
                handleClick={button1.handleClick}
              />
            )}
            {showViews?.button2 && (
              <components.LoadingButtonComponent
                buttonLabel={button2?.label || "Back"}
                buttonClassName={style.backbutton}
                handleClick={() => button2?.handleClick() || userId ? navigate('/admin/guides-listing'):  navigate(-1)}
              />
            )}
          </Grid>
          <Grid item sm={12} md={12} lg={12} mt={4}>
            <Typography className={style.mediumFontHeader}>Class Details</Typography>
          </Grid>
          <Grid item sm={12} lg={12} md={12}>
            <components.DataGridComponent
              columns={tableColumns}
              rows={pageState?.data}
              rowCount={pageState?.total}
              loading={pageState?.isLoading}
              page={pageState?.page}
              pageSize={pageState?.pageSize}
              onPageChange={(page: any) => {
                setPageState({ ...pageState, page });
              }}
              onPageSizeChange={(pageSize: any) => {
                setPageState({ ...pageState, pageSize });
              }}
            />
          </Grid>
        </Grid>
      )}
      {viewData?.isLoading && (
        <Box className={style.progressBarContainer}>
          <components.CircularProgressBar loadername={dashboardConst.LOADING_DATA} />
        </Box>
      )}
      {!viewData?.isLoading && !viewData?.data && (
        <Box className={style.errorPageContainer} gap={3}>
          <img
            src={IMAGES.linkExpiredImg}
            style={{ width: "fit-content", height: "fit-content" }}
            alt="link-expired"
          />
          <h2>{errorConst.OOPS_SOMETHING_WENT_WRONG}</h2>
        </Box>
      )}
    </>
  );
};
export const buttonStyle = {
  height: "40px",
  width: "100%",
  fontSize: "18px",
  color: "#FE6331",
  borderColor: "#FE6331",
  borderRadius: "6px",
  backgroundColor: "white",
  "&:hover": {
    borderColor: "#FE6331",
    backgroundColor: "rgba(254, 99, 49, 0.2)"
  },
};
export { DetailsViewPageComponent };
